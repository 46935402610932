$(document).ready(function() {
    swiperInit();
    newsProjectSlide();
    newsHomeSlide();
    partnerSlide();
    tabActive();
    sideNavigation();
    toggleMobileMenu();
    mappingMenu();
    mappingMenu2()
        // mappingContact();
    mappingSearch();
    projectDetailSlide();
    aboutHistory();
    changeIframe();
    linkAbout();
    showBackToTop();
    // mappingLogo();
    setTimeout(() => {
        $('#l_0').trigger("click")
    }, 500);
});

function linkAbout() {
    $(".link-to-about-section a").on("click", function(event) {
        if (this.hash !== "") {
            let offset =
                $("header").outerHeight();
            var hash = this.hash;
            $("html, body").animate({
                    scrollTop: $(hash).offset().top - offset,
                },
                800,
                function() {
                    window.location.hash = hash;
                }
            );
        } // End if
    });
}

function showBackToTop() {
    $(window).scroll(function() {
        if ($(this).scrollTop() > 0) {
            $('#back-to-top').addClass('active');
        } else {
            $('#back-to-top').removeClass('active');
        }
    });

    $("#back-to-top").on("click", function(e) {
        e.preventDefault();
        $("html,body").animate({
            scrollTop: 0
        })
    })
}

function changeIframe() {
    $(document).on('click', '.pnholding-maps ul li', function() {
        var data = $(this).attr('data-iframe')
        $('#custom-maps iframe').attr('src', data)
    })

}
//Check if windows size large then 1024 then these function will be execute
if ($(window).width() > 1024) {
    const $menu = $(".searchbox");
    $(document).mouseup(e => {
        if (!$menu.is(e.target) && // if the target of the click isn't the container...
            $menu.has(e.target).length === 0
        ) {
            // ... nor a descendant of the container
            $menu.removeClass("active");
        }
    });
}
// Remove when click outside the circle
function tabActive() {
    $(".tab-navigation li a").on("click", function() {
        $(this)
            .parents(".tab-navigation")
            .find("li")
            .removeClass("active");
        $(this)
            .parents("li")
            .addClass("active");

        var display = $(this).attr("data-type");
        $(".tab-item").removeClass("active");
        $("#" + display).addClass("active");
    });
}

function sideNavigation() {
    let trigger = $('.side-navigation-wrapper .side-navigation .title em');
    let close = $('.side-navigation-wrapper .side-navigation  .nav-sub');
    let parent = $('.side-navigation-wrapper .side-navigation  li');
    trigger.on('click', function(e) {
        e.preventDefault();
        if (!$(this).parent().parent().hasClass("open")) {
            close.slideUp();
            $(this).parent().next().slideToggle();
            parent.removeClass('open')
            $(this).parents('li').addClass('open')
        } else {
            $(this).parent().next().slideToggle();
            parent.removeClass('open')
        }
    })

}

function swiperInit() {
    var homerSwiper = new Swiper(".home-banner .swiper-container", {
        // Optional parameters
        speed: 1205,
        slidesPerView: 1,
        autoplay: {
            delay: 3000
        },
        pagination: {
            el: ".home-banner-pagination",
            type: "bullets",
            clickable: "true"
        }
    });
    $(".trinity-item-swiper .swiper-container").each(function(index, element) {
        var $this = $(this);
        $this.addClass("instance-" + index);
        $this.parent().find(".nav-arrow-prev").addClass("btn-prev-" + index);
        $this.parent().find(".nav-arrow-next").addClass("btn-next-" + index);

        var swiper = new Swiper(".instance-" + index, {
            speed: 750,
            observer: true,
            spaceBetween: 30,
            observeParents: true,
            breakpointsInverse: true,
            breakpoints: {
                200: {
                    slidesPerView: 1,
                },
                375: {
                    slidesPerView: 1,
                },
                576: {
                    slidesPerView: 1,
                },
                769: {
                    slidesPerView: 3,
                },
                1200: {
                    slidesPerView: 3,
                },
            },
            navigation: {
                nextEl: ".btn-next-" + index,
                prevEl: ".btn-prev-" + index
            }

        });
    });
    var homerSwiper = new Swiper(".project_city .swiper-container", {
        // Optional parameters
        speed: 1205,
        slidesPerView: 1,
        autoplay: {
            delay: 3000
        },
        pagination: {
            el: ".project_city-pagination",
            type: "bullets",
            clickable: "true"
        }
    });
    var brandSwiper = new Swiper(".project_detail_9 .swiper-container", {
        // Optional parameters
        speed: 1000,
        spaceBetween: 30,
        autoplay: {
            delay: 2000
        },
        navigation: {
            nextEl: '.project_detail_9 .nav-arrow-next',
            prevEl: '.project_detail_9 .nav-arrow-prev',
        },
        breakpointsInverse: true,
        breakpoints: {
            320: {
                slidesPerView: 1,

            },
            400: {
                slidesPerView: 1,

            },
            480: {
                slidesPerView: 1,

            },
            768: {
                slidesPerView: 3,

            },
            1025: {
                slidesPerView: 3,

            },
            1440: {
                slidesPerView: 3,

            },
            1600: {
                slidesPerView: 3,

            }
        },

    });
    var brandSwiper = new Swiper(".development_4 .swiper-container", {
        // Optional parameters
        speed: 1000,

        autoplay: {
            delay: 2000
        },
        navigation: {
            nextEl: '.development_4 .nav-arrow-next',
            prevEl: '.development_4 .nav-arrow-prev',
        },
        breakpointsInverse: true,
        spaceBetween: 20,
        breakpoints: {
            320: {
                slidesPerView: 1,
            },
            480: {
                slidesPerView: 1,
            },
            768: {
                slidesPerView: 2,
            },
            1025: {
                slidesPerView: 2,
            },
            1200: {
                slidesPerView: 2,
            },
            1440: {
                slidesPerView: 2,
            },
            1600: {
                slidesPerView: 2,
            },
        },
    });

    var brandSwiper = new Swiper(".brand-display .swiper-container", {
        slidesPerColumn: 2,
        spaceBetween: 20,
        slidesPerView: 6,
        speed: 1000,
        autoplay: false,
        loop: true,
        autoplay: {
            delay: 2000
        },
        navigation: {
            nextEl: '.brand-display .nav-arrow-next',
            prevEl: '.brand-display .nav-arrow-prev',
        },
        breakpointsInverse: true,
        breakpoints: {
            320: {
                slidesPerView: 2,
            },
            480: {
                slidesPerView: 2,
            },
            768: {
                slidesPerView: 4,
            },
            1025: {
                slidesPerView: 6,
            },
            1200: {
                slidesPerView: 6,
            },
            1440: {
                slidesPerView: 6,
            },
            1600: {
                slidesPerView: 6,
            },
        },

    });

}

// function aboutHistory() {
//     var galleryTop = new Swiper('.gallery-top', {
//         spaceBetween: 20,
//         navigation: {
//             nextEl: '.swiper-button-next',
//             prevEl: '.swiper-button-prev',
//         },
//         thumbs: galleryThumbs,
//         // loop: true,
//         // loopedSlides: 4
//     });
//     var galleryThumbs = new Swiper('.gallery-thumbs', {
//         // centeredSlides: true,
//         slidesPerView: 'auto',
//         // touchRatio: 0.2,
//         // slideToClickedSlide: true,
//         // loop: false,
//         // loopedSlides: 4,
//         // breakpoints: {
//         //     1100: {
//         //         slidesPerView: 2,
//         //     },
//         //     1024: {
//         //         slidesPerView: 2,
//         //     },
//         //     992: {
//         //         slidesPerView: 2,
//         //     },
//         //     575: {
//         //         slidesPerView: 2,
//         //     },
//         //     320: {
//         //         slidesPerView: 2,
//         //     }
//         // },

//     });
//     // if ($('body').hasClass('about-page')) {

//     //     galleryTop.controller.control = galleryThumbs;
//     //     galleryThumbs.controller.control = galleryTop;
//     // }

// }
function aboutHistory() {
    // return new Swiper('.about3_history .gallery-top', {
    //     spaceBetween: 10,
    //     // navigation: {
    //     //     nextEl: '.pvd-ab-6 .button-next',
    //     //     prevEl: '.pvd-ab-6 .button-prev',
    //     // },
    //     thumbs: {
    //         swiper: {
    //             el: '.about3_history .gallery-thumbs',
    //             // slidesPerView: 5,
    //             freeMode: true,
    //             // watchSlidesVisibility: true,
    //             // watchSlidesProgress: true,
    //             // breakpoints: {
    //             //     576: {
    //             //         slidesPerView: 4,
    //             //     },
    //             //     768: {
    //             //         slidesPerView: 6,
    //             //     },
    //             //     1025: {
    //             //         slidesPerView: 10,
    //             //     },
    //             //     1200: {
    //             //         slidesPerView: 15,
    //             //     }
    //             // }
    //         }
    //     },
    // });
    var galleryThumbs = new Swiper('.about3_history .gallery-thumbs', {
        spaceBetween: 10,
        slidesPerView: 4,
        // freeMode: true,
        // watchSlidesVisibility: true,
        // watchSlidesProgress: true,
    });
    var galleryTop = new Swiper('.about3_history .gallery-top', {
        spaceBetween: 10,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        // thumbs: {
        //     swiper: galleryThumbs
        // }
    });
    if ($('section').hasClass('about3_history')) {

        galleryTop.controller.control = galleryThumbs;
        galleryThumbs.controller.control = galleryTop;
    }
}

function projectDetailSlide() {
    var galleryTop = new Swiper('.project_detail .gallery-top', {
        // spaceBetween: 10,
    });
    var galleryThumbs = new Swiper('.project_detail .gallery-thumbs', {
        // spaceBetween: 10,
        centeredSlides: true,
        slidesPerView: 'auto',
        slideToClickedSlide: true,
        spaceBetween: 10,
        navigation: {
            nextEl: '.project_detail_slide_gallery .nav-arrow-prev',
            prevEl: '.project_detail_slide_gallery .nav-arrow-next',
        },
    });

    if ($('section').hasClass('project_detail')) {
        galleryTop.controller.control = galleryThumbs;
        galleryThumbs.controller.control = galleryTop;
    }

}

function newsProjectSlide() {
    var homerSwiper = new Swiper(".project_city .swiper-container", {
        // Optional parameters
        speed: 1205,
        slidesPerView: 1,
        autoplay: {
            delay: 2000
        },
        pagination: {
            el: ".project_city-pagination",
            type: "bullets",
            clickable: "true",
        },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev"
        },
    });
}

function newsHomeSlide() {
    var brandSwiper = new Swiper(".home-news .swiper-container", {
        // Optional parameters
        speed: 1000,
        observer: true,
        observeSlideChildren: true,
        autoplay: {
            delay: 2000
        },
        navigation: {
            nextEl: '.home-news .nav-arrow-next',
            prevEl: '.home-news .nav-arrow-prev',
        },
        breakpointsInverse: true,
        spaceBetween: 30,
        breakpoints: {
            320: {
                slidesPerView: 1,
            },
            480: {
                slidesPerView: 2,
            },
            768: {
                slidesPerView: 2,
            },
            1025: {
                slidesPerView: 3,
            },
            1200: {
                slidesPerView: 3,
            },
            1440: {
                slidesPerView: 3,
            },
            1600: {
                slidesPerView: 3,
            },
        },
    });
}

function partnerSlide() {
    var swiper = new Swiper('.block__partner .swiper-container', {
        slidesPerColumn: 2,
        spaceBetween: 20,
        slidesPerView: 6,
        speed: 1000,
        autoplay: false,
        loop: true,
        navigation: {
            nextEl: '.block__partner .swiper-button-next',
            prevEl: '.block__partner .swiper-button-prev',
        },
        breakpoints: {
            1100: {
                slidesPerView: 5,
            },
            1024: {
                slidesPerView: 4,
            },
            992: {
                slidesPerView: 3,
                spaceBetween: 10,
            },
            575: {
                slidesPerView: 2,
                spaceBetween: 10,
            },
            320: {
                slidesPerView: 2,
            }
        },

    });
}

function toggleMobileMenu() {
    $(".mobile-toggle").on("click", function() {
        $(".mobile-wrapper").toggleClass("active");
    });
}

function mappingMenu() {
    return new MappingListener({
        selector: ".Module-144",
        mobileWrapper: ".mobile-wrapper",
        mobileMethod: "appendTo",
        desktopWrapper: ".nav-menu-primary",
        desktopMethod: "prependTo",
        breakpoint: 1024.98
    }).watch();
}

function mappingMenu2() {
    return new MappingListener({
        selector: ".Module-153",
        mobileWrapper: ".mobile-wrapper",
        mobileMethod: "appendTo",
        desktopWrapper: ".search-wrapper",
        desktopMethod: "insertBefore",
        breakpoint: 1024.98
    }).watch();
}

function mappingContact() {
    return new MappingListener({
        selector: ".contact-wrapper",
        mobileWrapper: ".mobile-wrapper",
        mobileMethod: "appendTo",
        desktopWrapper: ".language-wrapper",
        desktopMethod: "insertBefore",
        breakpoint: 1025
    }).watch();
}

function mappingSearch() {
    return new MappingListener({
        selector: ".Module-224",
        mobileWrapper: ".mobile-wrapper",
        mobileMethod: "appendTo",
        desktopWrapper: ".search-wrapper",
        desktopMethod: "appendTo",
        breakpoint: 1025
    }).watch();
}

// function moblieLogo() {
//     $("header").appendTo("#foo");
// }
function mappingLogo() {
    // var mLogo = $('header .mobile-wrapper .nav-brand')
    if ($(window).width() < 1024) {
        $('header .mobile-wrapper .nav-brand').clone().prependTo('header .header-wrapper-right')

    }
}

// $(window).resize(function() {
//     if ($(window).width() <= 1024) {
//         $('.language').appendTo('.header-container #div-mobile');
//         $('.search').appendTo('.header-container #div-mobile')
//     } else {
//         $('.language').appendTo('.nav-item #language-desktop');
//         $('.search').appendTo('.nav-item #search-desktop');
//     }
// })
